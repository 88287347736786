<template>
  <div id="bg" class="body">
    <el-tabs style="width: 1000px;" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="充值返利" name="pay">
        <el-table :data="pay_config">
          <el-table-column prop="id" width="50px" label="序号">
            <template #default="scope">
              {{scope.$index}}
            </template>
          </el-table-column>
          <el-table-column prop="level" label="代理等级">
            <template #default="scope">
              <el-input v-model="scope.row.level"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="title" width="200px" label="标题">
            <template #default="scope">
              <el-input v-model="scope.row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="range"  width="200px" label="金额">
            <template #default="scope">
              <el-input type="number" v-model="scope.row.range"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="percent" label="返佣比">
            <template #default="scope">
              <el-input type="number" v-model="scope.row.percent"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="danger" size="mini" @click="delPayConfig(scope.$index)" icon="el-icon-delete"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="body_footer">
          <el-button type="primary" @click="addPayConfig" size="small">添加</el-button>
          <el-button type="primary" @click="savePayConfig" size="small">保存</el-button>
          <el-button @click="resetPayConfig" size="small">重置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="流水返利" name="tz">
        <el-table :data="tz_config">
          <el-table-column prop="id" width="50px" label="序号">
            <template #default="scope">
              {{scope.$index}}
            </template>
          </el-table-column>
          <el-table-column prop="level" label="代理等级">
            <template #default="scope">
              <el-input v-model="scope.row.level"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="title" width="200px" label="标题">
            <template #default="scope">
              <el-input v-model="scope.row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="range" width="200px" label="金额">
            <template #default="scope">
              <el-input type="number" v-model="scope.row.range"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="percent" label="返佣比">
            <template #default="scope">
              <el-input type="number" v-model="scope.row.percent"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="danger" size="mini" @click="delTzConfig(scope.$index)" icon="el-icon-delete"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="body_footer">
          <el-button type="primary" @click="addTzConfig" size="small">添加</el-button>
          <el-button type="primary" @click="saveTzConfig" size="small">保存</el-button>
          <el-button @click="resetTzConfig" size="small">重置</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import httpClient from "@/config/httpClient";

export default {
  name: "代理配置",
  components: {},
  data() {
    return {
      activeName:"pay",
      pay_config:[
      ],
      tz_config:[],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getPayConfig()
    this.getTzConfig()
  },
  methods: {
    getPayConfig(){
      httpClient("getAgentPayConfig").post({}).then((res)=>{
        if (res.code == 0 ){
          if (res.data){
            this.pay_config = JSON.parse(res.data)
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    savePayConfig(){
      let range = 0
      let index = 1
      for(var i in this.pay_config){
        let config = this.pay_config[i]
        if (parseFloat(config.range) < range ){
          return this.$message({message:`第${index}配置[${config.title}]错误`,type:"error"})
        }
        range = parseFloat(config.range)
        index++
      }
      httpClient("setAgentPayConfig").post({config:JSON.stringify(this.pay_config)}).then((res)=>{
        if (res.code == 0 ){
          this.$message({message:"保存成功",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    resetPayConfig(){
      this.getPayConfig()
    },
    addPayConfig(){
      this.pay_config.push({
        title:"",
        percent: 0,
        range:0
      })
    },
    delPayConfig(index){
      this.pay_config.splice(index,1)
    },

    getTzConfig(){
      httpClient("getAgentTzConfig").post({}).then((res)=>{
        if (res.code == 0 ){
          if (res.data){
            this.tz_config = JSON.parse(res.data)
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    saveTzConfig(){
      let range = 0
      let index = 1
      for(var i in this.tz_config){
        let config = this.tz_config[i]
        if (parseFloat(config.range) < range ){
          return this.$message({message:`第${index}配置[${config.title}]错误`,type:"error"})
        }
        range = parseFloat(config.range)
        index++
      }
      httpClient("setAgentTzConfig").post({config:JSON.stringify(this.tz_config)}).then((res)=>{
        if (res.code == 0 ){
          this.$message({message:"保存成功",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    resetTzConfig(){
      this.getTzConfig()
    },
    addTzConfig(){
      this.tz_config.push({
        title:"",
        percent: 0,
        range:0
      })
    },
    delTzConfig(index){
      this.tz_config.splice(index,1)
    }
  }
};
</script>
<style scoped>
#bg.body{
  padding:0 20px;
}
.body_footer{
  display: flex;
  justify-content: center;
}
</style>